import React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import { Container } from 'react-grid-system';
export default function Booking({}) {
  return (
    <Layout>
      <Seo title="PACE Cardiology newsletter" />
      <Container>
        <section className="py-14 lg:py-32">
          <div className="max-w-4xl mx-auto px-10 lg:px-0">
            <form
              action="https://mailwizz.e6labs.io/index.php/lists/pf8377mzjh688/subscribe"
              method="post"
              target="_blank"
            >
              <div className="space-y-12">
                <div>
                  <h2>
                    <em>PACE</em> Mailing List.
                  </h2>
                  <p className="mt-1">
                    We're happy you decided to subscribe to our email list.
                    Please take a few moments to fill in your details. You will
                    receive an email to confirm your subscription.
                  </p>

                  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="FNAME"
                        className="block text-2xl font-semibold leading-6 text-blue-100"
                      >
                        First name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="FNAME"
                          id="FNAME"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 text-sm leading-6"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="LNAME"
                        className="block text-2xl font-semibold leading-6 text-blue-900"
                      >
                        Last name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="LNAME"
                          id="LNAME"
                          autoComplete="family-name"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-4">
                      <label
                        htmlFor="EMAIL"
                        className="block text-2xl font-semibold leading-6 text-blue-900"
                      >
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                          id="EMAIL"
                          name="EMAIL"
                          type="email"
                          autoComplete="email"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-start gap-x-6 lg:pb-16 pt-10">
                <button
                  type="submit"
                  className="rounded-md bg-white p-5 border-solid border-2 border-red-500 text-3xl text-red-500 shadow-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500 transition-all duration-300 hover:bg-red-500 hover:text-white"
                >
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </section>
      </Container>
    </Layout>
  );
}
